import React from 'react'
import './topbar.scss'
import { Person, Mail } from "@material-ui/icons"
import AccountBoxIcon from '@material-ui/icons/AccountBox';


export default function Topbar({setMenuOpen, menuOpen}) {
  
    return (
        <div className={'topbar ' + (menuOpen && 'active')}>
            <div className='wrapper'>
                <div className="left">
                    <a href = '#intro' className='logo'>Josh</a>
                    <div className="itemContainer">
                        <Person/>
                        <span>516-477-1853</span>
                    </div> 
                    <div className="itemContainer">
                        <Mail className='icon'/>
                        <span>joshblitstein1@gmail.com</span>
                    </div> 
                    <a type='application/pdf' href='Resume.pdf' target='_blank'>
                    <div style={{cursor: 'pointer'}} className="itemContainer">
                        <AccountBoxIcon className='icon'/>
                        <span>Resume</span>
                    </div> 
                    </a>
                 
                </div>  
                
                
                <div className="right">
               
                    <a href='https://github.com/joshblitstein' target="_blank">
                    <div className="container">
                        <img className='hub' src='assets/github-logo.PNG' alt='assets/github-logo.PNG'/>
                    </div>
                    </a>
                    <a href='https://www.linkedin.com/in/joshua-blitstein-2013b7199/' target="_blank">
                    <div className="container">
                        <img className='hub' src='assets/link.png' alt='assets/link.png'/>
                    </div>
                    </a>
                  
                    <div className="hamburger" onClick ={()=> setMenuOpen(!menuOpen)}>
                        <span className='line1'></span>
                        <span className='line2'></span>
                        <span className='line3'></span>
                    </div>
                </div>
          </div>
        </div>
    )
}
