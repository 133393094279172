import { React, useState, useEffect }from 'react'
import Portlist from '../portlist/portlist'
import './portfolio.scss'
import {
    portfolioContent
} from '../../data.js'
export default function Portfolio() { 
    const [selected, setSelected] = useState('sample')
    const [toggle, setToggle] = useState(false)
function handleModal(){
    setToggle(!toggle)
}

      

    return (
        <div className='portfolio' id='portfolio'>
            <h1>Portfolio</h1>
            
      {
              !toggle ?
            <div className="container">
            <a href='http://dynomitedesigns.com/' target="_blank">
                <div className="item">
                 <img src='assets/dyno.png' alt='assets/dyno.png'/>
                 <label>Design Company</label>
                 <div className="cont">
                 <a href='https://github.com/joshblitstein/Dyno-site' target="_blank">View Code</a>
                 </div>
                </div>
                </a>
            <a href='https://meta-data-aa2e9.web.app/' target="_blank">
                <div className="item">
                 <img src='assets/music.png' alt='assets/music.png'/>
                 <label>Music Company</label>
                 <div className="cont">
                 <a href='https://github.com/joshblitstein/maximus' target="_blank">View Code</a> 
                 </div>
                </div>
                </a>
            <a href='https://audio-player-ebon.vercel.app/' target="_blank">
                <div className="item">
                 <img src='assets/Audio.png' alt='assets/Audio.png'/>
                 <label>Audio Player</label>
                 <div className="cont">
                 <a href='https://github.com/joshblitstein/Audio-Player/' target="_blank">View Code</a>
                 </div>
                </div>
                </a>
            <a href='https://collectors-c24cc.web.app/' target="_blank">
                <div className="item">
                 <img src='assets/secondaryLogo.jpg' alt='assets/secondaryLogo.jpg'/>
                 <label>Watch Company</label>
                 <div className="cont">
                 <a href='https://github.com/joshblitstein/Collectors' target="_blank">View Code</a>
                 </div>
                </div>
                </a>
            <a href='https://generative-art-p5-js.vercel.app/' target="_blank">
                <div className="item">
                 <img src='assets/gen.png' alt='assets/gen.png'/>
                 <label>Generative Art</label>
                 <div className="cont">
                 <a href='https://github.com/joshblitstein/generative-art-p5.js' target="_blank">View Code</a>
                 </div>
                </div>
                </a>
            <a href='https://product-filter.web.app/' target="_blank">
                <div className="item">
                 <img src='assets/filter.png' alt='assets/filter.png'/>
                 <label>Automation Comapny</label>
                 <div className="cont">
                 <a href='https://github.com/joshblitstein/custom-scripts' target="_blank">View Code</a>
                 </div>
                </div>
                </a>
            <a href='https://github.com/joshblitstein/Commands' target="_blank">
                <div className="item">
                 <img src='assets/command.png' alt='assets/command.png'/>
                 <label>Command Line tools</label>
                 <div className="cont">
                 <a href='https://github.com/joshblitstein/Commands' target="_blank">View Code</a>
                 </div>
                </div>
                </a>
            <a >
                <div onClick={handleModal} className="item">
                 <img src='https://iapm.ie/wp-content/uploads/2019/07/BANNER-SIZE-AI-and-Machine-Learning-e1562764755877.jpg' alt='assets/filter.png'/>
                 <label>Machine Learning App</label>
                 <div className="cont">
                 {/* <a href='https://www.github.com/' target="_blank">View Code</a> */}
                 </div>
                </div>
                </a>

         
     
              
            </div>
            :
            <div className='container'>
                <p style={{margin: '20px'}}>
                This was a project that i worked on with California Polytechnic University, unfortunately the code it private and the project was a machine learning desktop app
                that is not hosted on the internet. I worked on sending and receiving data from the AI models in the background and displaying the models progress as it tested incoming data against pre determined models.
                I also learned alot about building desktop apps and background processes. The project was funded by a grant. I worked with a team of talented developers from all over the world. 
                </p>
                <button onClick={()=>setToggle(false)}>Back</button>
            </div>}
        </div>
     
    )
}
 