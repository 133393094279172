import { React, useEffect, useRef } from 'react'
import './intro.scss'
import{ init } from 'ityped'

export default function Intro() {

    const textRef = useRef()

    useEffect(()=>{
        init(textRef.current, 
            { 
            showCursor: true, 
            backDelay: 1500,
            typeSpeed: 150,
            backSpeed: 60,
            strings: ['React.js', 'CSS', "Bootstrap", "HTML5", "MERN", "Node.js","MongoDb","Electron.js","P5.js" ]
            }
            )    },[])


    return (
        <div className='intro' id='intro'>
            <div className="left">
                <div className="imgContainer">
                    <img src='assets/prof.PNG' alt='assets/prof.PNG'/>
                </div>
            </div>
            <div className="right">
                   <div className="wrapper">
                       <h2>Hi, i'm</h2>
                       <h1>Josh Blitstein</h1>
                       <h3>Web Design</h3>
                       <h3 >Technologies: <span ref={textRef}></span></h3>
                   </div>
                   <a href='#portfolio'>
                       <img src='assets/Down.PNG' alt='assets/Down.PNG'/>
                   </a>
            </div>
        </div>
    )
}
