import React, {useRef, useState} from 'react'
import './contact.scss'
import emailjs from '@emailjs/browser';

export default function Contact() {
    const form = useRef();
    const [toggle,setToggle] = useState(false)

    function sendEmail(e){
        setToggle(true)
        e.preventDefault();
        console.log(form.current)
        emailjs.sendForm("service_17ypoyv","template_vef65zc", form.current, 'user_lQR9QRWC4KAKeGdj20DSE')
          .then((result) => {
              console.log(result.text);
          }, (error) => { 
              console.log(error.text);
          });
    }



    return (
        <div className='contact' id='contact'>
            <div className="left">
                <div className="top">
                    <div className="one">
                        <div className="ok">

                        </div>
                    </div>
                    <div className="two">
                        <div className="ko">

                        </div>
                    </div>
                </div>
                <div className="bottom">

                </div>
               {/*  <img src="assests/Down.png" alt="assests/Down.png" /> */}
            </div>
            <div className="right">
                <h2>Get in touch</h2>

                <form ref={form} onSubmit={sendEmail} >
                    <input type="email" placeholder='Email' required/>
                    <textarea placeholder='Message'></textarea>
                    <button type='submit'>Submit</button>
                </form>
            </div>
        </div>
    )
}
